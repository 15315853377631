@import 'styles/variables';

.br-order-info-container {
  border: 1px solid @border-color-23;
  padding: 24px;
  padding-bottom: 16px;
  border-radius: 8px;
  margin-top: 24px;
}

.br-order-info-container.notes {
  height: 563px;
  max-height: 563px;
  display: flex;
  flex-direction: column;
  padding: 0;
  & .br-order-info-section__title {
    padding: 24px;
    padding-bottom: 0;
  }
}

.br-order-info-container.star_notes {
  height: 270px;
  max-height: 270px;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;
  & .br-order-info-section__title {
    padding: 24px;
    padding-bottom: 0;
  }
}

.br-order-info-container.whats_app_container {
  border-radius: 12px;

  padding: 0px;
  .br-order-info-section__header {
    padding: @spacing-lg;
    height: 60px;
    border-bottom: 1px solid @gray-200;
  }
}

.br-order-info-container.delivery_highlights {
  height: 270px;
  max-height: 270px;
  display: flex;
  flex-direction: column;
  padding: 0;
  & .br-order-info-section__title {
    padding: 24px;
    padding-bottom: 0;
  }
}

.br-order-info__col-8 {
  margin-left: 24px;
}

.br-order-info-section__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  & p {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 16px;
    font-family: @poppins-font-600;
    color: @text-color-49;
  }
}

.br-order-info-section__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;

  & button {
    & span {
      font-size: 14px;
      font-family: @lato-font-700;
      color: @text-color-47;
    }
  }
}

.br-order-info__col-data {
  display: flex;
  flex-direction: column;
  & span:first-child {
    margin-bottom: 8px;
    color: @text-color-45;
    font-family: @lato-font-700;
    font-size: 12px;
    line-height: 14.4px;
  }

  & span {
    font-size: 14px;
    font-family: @lato-font-400;
    color: @text-color-46;
    line-height: 16.8px;
  }
  & .br-pickup-request-link {
    color: @text-color-48;
    font-family: @lato-font-400;
    text-decoration: underline;
    font-size: 14px;
  }

  & .br-order-info-delivery-details__state {
    font-family: @lato-font-700;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 10px;
  }

  & .br-order-info-delivery-details__state-fawry-tag {
    font-family: @lato-font-700;
    font-size: 12px;
    margin-left: 8px;
    color: @text-color-47;
    & svg {
      margin-right: 8px;
    }
  }

  & .br-order-info-delivery-details__state.br-state-blue-group {
    background-color: @background-color-21;
    border-color: @background-color-21;
    color: @text-color-37;
  }
  & .br-order-info-delivery-details__state.br-state-green-group {
    background-color: @background-color-22;
    border-color: @background-color-22;
    color: @text-color-29;
  }
  & .br-order-info-delivery-details__state.br-state-purple-group {
    background-color: @background-color-23;
    border-color: @background-color-23;
    color: @text-color-31;
  }
  & .br-order-info-delivery-details__state.br-state-red-group {
    background-color: @background-color-24;
    border-color: @background-color-24;
    color: @text-color-27;
  }
  & .br-order-info-delivery-details__state.br-state-yellow-group {
    background-color: @background-color-25;
    border-color: @background-color-25;
    color: @text-color-36;
  }

  & .br-delivery-details__COD-refund {
    & span {
      font-size: 14px;
      font-family: @lato-font-400;
      color: @text-color-46;
      line-height: 16.8px;
      margin-right: 8px;
    }
    & .br-delivery-details__COD-refund-tag {
      font-size: 12px;
      color: @text-color-29;
      padding: 4px 8px;
      background-color: @background-color-22;
      border-radius: 10px;
      font-family: @lato-font-700;
    }
  }

  & .br-delivery-details__collected-at-date-content {
    & span {
      font-size: 14px;
      color: @text-color-46;
    }
    & .br-delivery-details__collected-at-date {
      color: #9ca3af;
      font-size: 11px;
    }
  }
  & span.br-order-info__customer-details-address {
    font-size: 14px;
    font-family: @lato-font-400;
    color: @text-color-46;
    line-height: 21.63px;
  }
}

.br-order-info__row-content {
  margin-bottom: 32px;
  width: 100%;
}

.br-order-info-section__second-header {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: bold;
}

.br-order-info-section__content div {
  width: 100%;
}

.br-order-info-section__content__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 149px;
  border: 1px solid @gray-100;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 24px;
  img {
    height: 90%;
    max-width: 149px;
    padding: 10px;
  }
}

.br-order-info-section__center-content {
  align-items: center;
  text-align: center;
  height: 100%;
}

.br-order-info-section_rate-star-action.ant-btn {
  margin-top: 8px;
  padding: 7px;

  & span {
    font-size: 11px;
    color: @text-color-47;
  }

  & svg {
    margin-right: 5px;
  }
}

.br-delivery-details__consignee-rate {
  .br-order-info-section__center-content {
    text-align: unset;
  }

  .br-service-rating__label {
    color: @text-gray;
    .font({.caption-medium()});
  }
}
