@import 'styles/variables';

.br-bonus-and-deduction-details__image-container
  .ant-descriptions-item-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 200px;
}

.br-bonus-and-deduction-details__image {
  width: 34px;
}

.br-bonus-and-deduction-details__image:not(:last-child) {
  margin-right: 4px;
}

.br-bonus-and-deduction-details .br-content-header__title {
  font-size: 20px;
  display: flex;
  justify-content: space-between;

  & .anticon {
    cursor: pointer;
    font-size: 12px;
  }
}

.br-bonus-and-deduction-details {
  & .ant-modal {
    width: 618px;
  }
  & .ant-descriptions-item-container {
    flex-direction: column;
  }
  & .ant-descriptions-item-label {
    font-family: @lato-font-700;
    font-size: 13px;
    color: @text-color-45;
  }
  & .ant-descriptions-item-content {
    font-family: @lato-font-400;
    font-size: 16px;
    color: @text-color-46;
  }
  & .ant-descriptions-item-label::after {
    display: none;
  }

  & .ant-tag {
    font-size: 12px;
  }
}

.br-bonus-and-deduction-details__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
  & .ant-btn:first-child {
    margin-right: 12px;
  }
}

.ant-tag.br-bonus-and-deduction-details__status_rejected {
  color: #991b1b;
  background: #fee2e2;
  border: #fee2e2;
}

.ant-tag.br-bonus-and-deduction-details__status_pending {
  color: #92400e;
  background: #fef3c7;
  border: #fef3c7;
}

.ant-tag.br-bonus-and-deduction-details__status_approved {
  color: #065f46;
  background: #d1fae5;
  border: #d1fae5;
}

.br-bonus-and-deduction-details__status span:not(:first-child) {
  color: @text-color-45;
  font-size: 11px;
}

.br-bonus-and-deduction-details__status span:nth-child(2) {
  margin-right: 3px;
}
