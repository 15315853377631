@import 'styles/variables';

.br-order-info-section__content .chat-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  max-height: 500px;
  overflow-y: auto;
}

.br-order-info-section__content .chat-wrapper .message-wrapper {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: flex-start;
}

.chat-wrapper .message-wrapper .message-content {
  padding: 8px 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  width: 175px;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border: 1px solid @red-100;
  min-height: 60px;
  justify-content: space-between;
  align-items: start;
}

.bosta-message-wrapper {
  align-self: flex-start;
  .message-content {
    background-color: @red-100;
  }
}

.br-order-info-section__content .chat-wrapper .consignee-message-wrapper {
  align-self: flex-end;
  justify-content: flex-end;
  .message-content {
    background-color: @gray-50;
    border-color: @gray-200;
  }
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid @gray-200;
  border-radius: 100%;
  padding: 8px;
}

.time-stamp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @gray-500;
}

.time-stamp span {
  margin-inline-start: 4px;
}

.iconWrapper-read path {
  fill: @blue-700;
}

.time-stamp span svg {
  width: 16px;
  height: 16px;
}
